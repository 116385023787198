import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetMarketByUserService, CreateMarketService, GetAllListMarket, CreateListMarket } from '../../../../services/marketService'
import { AxiosError } from 'axios'

export const getAllMarketsById = createAsyncThunk(
    'market/getAllMarketsById',
    async ({ user }: { user: number }, thunkAPI) => {
        try {
            const { data } = await GetMarketByUserService({ user })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const CreateMarket = createAsyncThunk(
    'market/createMarket',
    async ({ market }: { market: any }, thunkAPI) => {
        try {
            const { data } = await CreateMarketService({ market })
            return data;
        } catch (error) {
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const CreateListItemMarket = createAsyncThunk(
    'market/createListMarket',
    async ({ idMarket, DataListMarket }: { idMarket: number, DataListMarket: any }, thunkAPI) => {
        try {
            console.log("entro")
            const { data } = await CreateListMarket({ idMarket, DataListMarket })
            console.log(`data de respuesta del servidor`,data)
            return data;
        } catch (error) {
            console.log("ubo un error desde el servidor", error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)



export const GetListMarket = createAsyncThunk(
    'market/getById',
    async (_, thunkAPI) => {
        try {
            const { data } = await GetAllListMarket()
            return data;
        } catch (error) {
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)