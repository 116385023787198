import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllListById } from './thunks/allListThunk';

interface IinitialState {
    list: [],
    error: string
}

const initialState: IinitialState = {
    list: [],
    error: ''
}

const listSlice = createSlice({
    name: "list",
    initialState,
    reducers: {},
    extraReducers: {
        [getAllListById.fulfilled.type]: (state: IinitialState, action: PayloadAction<[]>) => {
            state.list = action.payload
        },
        [getAllListById.rejected.type]: (state: IinitialState, action: PayloadAction<string>) => {
            state.error = action.payload
        }
    },
})
const { reducer } = listSlice;

export default reducer;
