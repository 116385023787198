import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonSlide, IonSlides, IonTitle, IonToolbar, useIonAlert } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Header from '../../../components/header/Header'
import NewPage from '../../../components/newPage'
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner';
import './AgregarMercado.css'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { GetProductThunk, updateOutCard, DeleteCountProducts } from '../../../redux/slices/products/thunks/getProductsThunk'

function AgregarMercado() {
    const modal = useRef(null);
    const input = useRef(null);
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const { state } = useLocation();
    const dispatch = useDispatch()
    const [showAlert] = useIonAlert();

    const [nombreCanasta, setnombreCanasta] = useState("")
    const [canastas, setcanastas] = useState([])
    const [activecanasta, setactivecanasta] = useState(0)

    useEffect(() => {
        if (!state.canastas) {
            let newCanasta = {
                nameMarket: state.nombreCanasta,
                store: state.name,
                products: []
            }
            setcanastas(oldArray => [...oldArray, newCanasta])
        } else {
            setcanastas(state.canastas)
        }
        return null
    }, [state])

    const irAtras = () => {
        history.push("/inicio")
    }
    const leerCodigoBarras = async () => {
        try {
            // const data = await BarcodeScanner.scan();
            // let codigo = data.text
            dispatch(GetProductThunk({ code: 5 }))
                .unwrap()
                .then((data) => {
                    let product = canastas[activecanasta]?.products.filter(product => product.id === data.id)
                    let countProducts = { ...data, amount: product.length ? product[0].amount + 1 : 1 }
                    let newList = canastas[activecanasta]?.products.map(product => {
                        if (product.id === data.id) {
                            product = countProducts
                        }
                        return product
                    })
                    if (newList.length) {
                        const newListCanastas = canastas.map((canasta, i) => {
                            if (activecanasta === i) {
                                canasta.products = newList
                            }
                            return canasta
                        })
                        setcanastas(newListCanastas)
                    }
                    else {
                        const newListCanastas = canastas.map((canasta, i) => {
                            if (activecanasta === i) {
                                canasta.products = [...canasta.products, countProducts]
                            }
                            return canasta
                        })
                        setcanastas(newListCanastas)
                    };
                })
                .catch(err => {
                    console.log(err)
                    showAlert({
                        cssClass: 'my-css',
                        message: `Producto No encotrado`,
                        buttons: [
                            { text: "Ok" }
                        ]
                    });
                })
        } catch (error) {
            showAlert({
                cssClass: 'my-css',
                message: `Plugin no disponible`,
                buttons: [
                    { text: "Ok" }
                ]
            });
        }
    }

    const EliminarProductos = (id) => {
        let product = canastas[activecanasta]?.products.filter(product => product.id === id)
        if (product[0].amount === 1) {
            showAlert({
                cssClass: 'my-css',
                message: `Deseas Eliminar el Producto`,
                buttons: [
                    { text: "Cancelar" },
                    {
                        text: "Ok",
                        handler: () => {
                            let productsList = canastas[activecanasta]?.products.filter(product => product.id !== id)
                            const newListCanastas = canastas.map((canasta, i) => {
                                if (activecanasta === i) {
                                    canasta.products = productsList
                                }
                                return canasta
                            })
                            setcanastas(newListCanastas)
                            dispatch(DeleteCountProducts({ outCard: 1, productID: id }))
                                .unwrap()
                                .then(() => console.log('Producto Actualizado con exito eliminado '))
                                .catch(() => console.log('Error Actualizando el producto eliminado'))

                        }
                    },
                ]
            });
        } else {
            showAlert({
                cssClass: 'my-css',
                message: `Elimnando Productos`,
                buttons: [
                    {
                        text: "Eliminar Todos",
                        handler: () => {
                            let productsList = canastas[activecanasta]?.products.filter(product => product.id !== id)
                            const newListCanastas = canastas.map((canasta, i) => {
                                if (activecanasta === i) {
                                    canasta.products = productsList
                                }
                                return canasta
                            })
                            setcanastas(newListCanastas)
                            dispatch(DeleteCountProducts({ outCard: product[0].amount, productID: id }))
                                .unwrap()
                                .then(() => console.log('Producto Actualizado con exito eliminado '))
                                .catch(() => console.log('Error Actualizando el producto eliminado'))
                        }
                    },
                    {
                        text: "Eliminar 1",
                        handler: () => {
                            let countProducts = { ...product[0], amount: product[0].amount - 1 }
                            let newlistProduct = canastas[activecanasta]?.products.map(prod => {
                                if (prod.id === countProducts.id) {
                                    prod = countProducts
                                }
                                console.log(prod)
                                return prod
                            })
                            const newListCanastas = canastas.map((canasta, i) => {
                                if (activecanasta === i) {
                                    canasta.products = newlistProduct
                                }
                                return canasta
                            })
                            setcanastas(newListCanastas)
                        }
                    },
                    { text: "Cancelar" },
                ]
            });
        }

    }

    const totalCanasta = () => {
        return canastas[activecanasta]?.products.reduce((previousValue, currentValue) => {
            let suma = (previousValue !== 0 ? previousValue.price * previousValue.amount : 0) + (currentValue.price * currentValue.amount)
            return suma
        }, 0)
    }

    const TerminarMercar = () => {
        let canastafilter = canastas.filter(ca => ca.products.length === 0)
        if (canastafilter.length > 0) {
            return showAlert({
                cssClass: 'my-css',
                message: `Alguna de tus canastas no tiene productos`,
                buttons: [
                    { text: "Ok" }
                ]
            });
        }

        console.log(canastas)

        history.push('/resumencompra', canastas)
    }
    const CrearCanasta = () => {
        let newCanasta = {
            nameMarket: nombreCanasta,
            store: state.name,
            products: []
        }
        setcanastas(oldArray => [...oldArray, newCanasta])
        modal.current?.dismiss()
        setIsOpen(false)
    }

    const eliminarCanasta = () => {
        showAlert({
            cssClass: 'my-css',
            message: `Vas a eliminar esta canasta y todos sus productos`,
            buttons: [
                {
                    text: "Ok", handler: () => {
                        let newCanastas = canastas.filter(ca => ca.nameMarket !== canastas[activecanasta].nameMarket)
                        setcanastas(newCanastas)
                        setactivecanasta(0)
                    }
                },
                { text: "Cancelar" }
            ]
        });

    }

    return (
        <NewPage>
            <Header Titulo={"true"} TituloText={`Canasta ${canastas[activecanasta]?.nameMarket}`} Tamano={"true"} Back={"true"} irAtras={irAtras} />
            <IonContent scrollEvents={true} className="IonContent" >
                <section className="inside" >
                    <div className="divider"></div>
                    <div className="row lista-canasta">
                        {
                            canastas[activecanasta]?.products.length ?
                                <table>
                                    <thead>
                                        <tr className='cabeceraTabla'>
                                            <th></th>
                                            <th>Productos {canastas[activecanasta]?.products.length}</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            canastas[activecanasta].products.map((product, i) => (
                                                <tr key={i}>
                                                    <td className="eliminar-producto">
                                                        <span onClick={() => EliminarProductos(product.id)}>
                                                            x
                                                        </span>
                                                    </td>
                                                    <td className="producto">
                                                        <img src={product.image} alt='img-product' />
                                                        <div className="info-producto">
                                                            <p className="nombre-producto">{product.name}</p>
                                                            <p className="descripcion-producto">{product.name} x  {product.stock}</p>
                                                            <p className="precio-producto">Valor Unidad: ${product.price}</p>
                                                            <p className="cantidad-producto">Cantidad: {product.amount}</p>
                                                        </div>
                                                    </td>
                                                    <td className='precio'>${product.price * product.amount}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                <div className='NoexisteProductos'>
                                    <h4>
                                        Añade productos a tu canasta escaneando el código de barras de cada producto, así tendrás el precio
                                        correcto.
                                    </h4>
                                </div>
                        }
                    </div>
                </section>
            </IonContent >
            <div className="fin">
                <div className="agregar-producto" onClick={leerCodigoBarras} style={{ cursor: "pointer" }}>
                    <a className="border-all">
                        <span className="icon-plus icon-green"><span className="path1"></span></span>
                    </a>
                    <span >
                        Añadir productos a mi canasta
                    </span>
                </div>
                {
                    canastas.length > 1 &&
                    <div className="agregar-producto" onClick={eliminarCanasta} style={{ cursor: "pointer" }}>
                        <span style={{ fontSize: '25px' }} className="icon-delete"><span className="path1"></span></span>
                        <span >
                            Eliminar Mi canasta
                        </span>
                    </div>
                }

                <div className="total-canasta">
                    Total de tu canasta: ${totalCanasta()}
                </div>
                <ion-accordion-group>
                    <ion-accordion value="first">
                        <ion-item slot="header" color="light">
                            <ion-label>Otras Canastas</ion-label>
                        </ion-item>
                        <div class="ion-padding" slot="content">
                            {
                                canastas.map((canasta, i) => (
                                    <IonButton onClick={() => setactivecanasta(i)} key={i}>{canasta.nameMarket}</IonButton>
                                ))
                            }
                            <IonButton onClick={() => setIsOpen(true)}>Crear Nueva Canasta</IonButton>
                        </div>
                    </ion-accordion>
                </ion-accordion-group>
                <nav className="z-depth-4 border-up violet-bg">
                    <div className="nav-wrapper center">
                        <button
                            onClick={TerminarMercar}
                            className="waves-effect waves-light btn-large border-all white-mercadu">
                            Terminar de mercar
                        </button>
                    </div>
                </nav>
            </div>
            <IonModal isOpen={isOpen} initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 0.75]} onWillDismiss={(ev) => setIsOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => {
                                modal.current?.dismiss()
                                setIsOpen(false)
                            }} >Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Welcome</IonTitle>
                        <IonButtons slot="end">
                            <IonButton strong={true} onClick={CrearCanasta}>
                                Confirm
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonItem>
                        <IonLabel position="stacked">Crear Canasta</IonLabel>
                        <IonInput ref={input} type="text" placeholder="Nombre Canasta" onIonChange={e => setnombreCanasta(e.target.value)} />
                    </IonItem>
                </IonContent>
            </IonModal>
        </NewPage >
    )
}

export default AgregarMercado
