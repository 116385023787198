import Api from '../utils/ConectionApi';
import authHeader from './AuthHeader'

const BaseUrl = 'products/'

export const GetProductService = async ({ code }) => {
    let headers = await authHeader()
    return Api({
        method: 'GET',
        url: `${BaseUrl}barCode/${code}`,
        headers
    })
}

export const UpdateInCardService = async ({ id, inCard }) => {
    let headers = await authHeader()
    return Api({
        method: 'PATCH',
        url: `${BaseUrl}update/${id}`,
        data: {
            inCard
        },
        headers
    })
}

export const UpdateOutCardService = async ({ id, outCard }) => {
    let headers = await authHeader()
    return Api({
        method: 'PATCH',
        url: `${BaseUrl}update/${id}`,
        data: {
            outCard
        },
        headers
    })
}

export const GetAllProducts = async ({ page }) => {
    let headers = await authHeader()
    return Api({
        method: 'GET',
        url: `${BaseUrl}all?page=${page}`,
        headers
    })
}


export const DeleteCardProducts = async ({ outCard, productID }) => {
    let headers = await authHeader()
    return Api({
        method: 'PUT',
        url: `${BaseUrl}updateSatisfactoryandDeleteProductsCard/${productID}`,
        headers,
        data: {
            outCard
        }
    })
}

export const AddCardProducts = async ({ inCard, productID }) => {
    let headers = await authHeader()
    return Api({
        method: 'PUT',
        url: `${BaseUrl}updateSatisfactoryandDeleteProductsCard/${productID}`,
        headers,
        data: {
            inCard
        }
    })
}