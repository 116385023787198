import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetListByUserId, PostListByUserId, PostCreateItemList, DeleteItemList,DeleteListApi } from '../../../../services/list.service'
import { AxiosError } from 'axios'
import { get } from '../../../../utils/ionicStorage';
import { decodeToken } from 'react-jwt';


export const getAllListById = createAsyncThunk(
    'list/getById',
    async ({ id }: { id: number }, thunkAPI) => {
        try {
            const { data } = await GetListByUserId({ id })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const createList = createAsyncThunk(
    'list/create',
    async ({ nameList }: { nameList: string }, thunkAPI) => {
        try {
            console.log(nameList)
            const { data } = await PostListByUserId({ nameList })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createItemList = createAsyncThunk(
    'list/createItem',
    async ({ nameitem, listID }: { nameitem: string, listID: number }, thunkAPI) => {
        try {
            const { data } = await PostCreateItemList({ nameitem, listID })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteItemList2 = createAsyncThunk(
    'list/deleteItem',
    async ({ listitemID }: { listitemID: number }, thunkAPI) => {
        try {
            const { data } = await DeleteItemList({ listitemID })
            const token = await get('_token_user')
            const { userId }: any = decodeToken(token);
            thunkAPI.dispatch(getAllListById({ id: userId }))
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteListAction = createAsyncThunk(
    'list/deleteList',
    async ({ idList }: { idList: number }, thunkAPI) => {
        try {
            const { data } = await DeleteListApi({ idList })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)
