import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Header from '../../../components/header/Header';
import NewPage from '../../../components/newPage';
import { useHistory } from 'react-router-dom';
import { useIonAlert } from '@ionic/react';


function RegistrarProducto() {
    const { state } = useLocation();
    const history = useHistory()
    const [tienda, setTienda] = useState()
    const [codigoproducto, setCodigo] = useState()
    const [showAlert, hideAlert] = useIonAlert();

    const [dataProducto, setDataProducto] = useState({
        nombre: "",
        imagen: "",
        codigo: codigoproducto,
        unidades: 0,
        valor: 0,
    })

    useEffect(() => {
        setTienda(state.tienda)
        setCodigo(state.codigo)
        return null
    }, [])

    const irAtras = () => {
        history.push("/agregarmercado", { ...tienda })
    }

    const AgregarProducto = async (e) => {
        e.preventDefault()

        fetch(`http://198.199.121.249:3333/api/products/create`, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nombre: dataProducto.nombre,
                imagen: dataProducto.imagen,
                codigo: codigoproducto,
                unidades: dataProducto.unidades,
                valor: dataProducto.valor
            })
        }).then(res => {
            return res.json()
        })
            .then(res => {
                if (res.message == "Producto correctamente") {
                    showAlert({
                        cssClass: 'my-css',
                        header: 'Alert',
                        message: `Producto Registrado correctamente`,
                        buttons: ['Ok'],
                    });
                    history.push("/agregarmercado", { ...tienda })
                } else {
                    showAlert({
                        cssClass: 'my-css',
                        header: 'Error',
                        message: `Error ${res.message}`,
                        buttons: ['Ok'],
                    });
                }
            })
            .catch(err => {
                console.log("este es el error", err);
            })
    }
    return (
        <NewPage>
            <Header Titulo={"true"} TituloText={`Registrar Producto ${codigoproducto}`} Tamano={"true"} Back={"true"} irAtras={irAtras} />
            <section className="small-container">
                <h3 className="center-align subtitle-mercadu"><b>Registra</b> tu producto para encontrarlo en la proxima scaneada.</h3>
                <p className='center-align'>Codigo Producto: {codigoproducto}</p>
                <div className="row">
                    <form className="col s12" >
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" placeholder='Nombre Producto' value={dataProducto.nombre} onChange={(e) => setDataProducto({ ...dataProducto, nombre: e.target.value })} />
                            </div>
                            <div className="input-field col s12">
                                <input type="text" placeholder='url Imagen Producto' value={dataProducto.imagen} onChange={(e) => setDataProducto({ ...dataProducto, imagen: e.target.value })} />
                            </div>
                            <div className="input-field col s12">
                                <input type="number" placeholder='Unidades Disponibles' value={dataProducto.unidades} onChange={(e) => setDataProducto({ ...dataProducto, unidades: e.target.value })} />
                            </div>
                            <div className="input-field col s12">
                                <input type="number" placeholder='Valor Producto' value={dataProducto.valor} onChange={(e) => setDataProducto({ ...dataProducto, valor: e.target.value })} />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col s12">
                                <div className="center-align">
                                    <button type="submit" className="waves-effect waves-light btn-large border-all green-mercadu" onClick={AgregarProducto}>Registrar Producto</button>
                                </div>
                            </div>
                            <div className="col s12 m3" style={{ paddingTop: "10px" }}>
                                <div className="center-align">
                                    <button className="waves-effect waves-light btn-large border-all red" onClick={irAtras}>Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </NewPage>
    );
}

export default RegistrarProducto;
