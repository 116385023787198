import Api from '../utils/ConectionApi';
import authHeader from './AuthHeader'

const BaseUrl = 'market/'
const BaseUrlList = 'itemmarket/'

export const GetMarketByUserService = async ({ user }) => {
    let headers = await authHeader()
    return Api({
        method: 'GET',
        url: `${BaseUrl}user/${user}`,
        headers
    })
}

export const CreateMarketService = async ({ market }) => {
    let headers = await authHeader()
    return Api({
        method: 'POST',
        url: `${BaseUrl}create`,
        headers,
        data: market
    })
}



export const GetAllListMarket = async () => {
    let headers = await authHeader()
    return Api({
        method: 'GET',
        url: `${BaseUrlList}all`,
        headers
    })
}

export const CreateListMarket = async ({ idMarket, DataListMarket }) => {
    console.log("entro a la funcion")
    let headers = await authHeader()
    return Api({
        method: 'POST',
        url: `${BaseUrlList}create/${idMarket}`,
        headers,
        data: DataListMarket
    })
}