import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllProducts } from './thunks/getProductsThunk';

interface IinitialState {
    products: [],
    error: string
}

const initialState: IinitialState = {
    products: [],
    error: ''
}

const authSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: {
        [getAllProducts.fulfilled.type]: (state: IinitialState, action: PayloadAction<[]>) => {
            state.products = action.payload
        },
        [getAllProducts.rejected.type]: (state: IinitialState, action: PayloadAction<string>) => {
            state.error = action.payload
        }
    },
})
const { reducer } = authSlice;

export default reducer;
