import React from 'react'
import { IonIcon, IonButtons, IonMenuToggle, IonMenuButton } from '@ionic/react';
import { arrowBackOutline, personCircleOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import Logo from '../../assets/img/logo-mercadu-22.svg'

import './Header.css'

const Header = ({ LogoPricipal, Pestañas, tituloPestana, strongPestana, Titulo, TituloText, TituloStrong, Back, Tamano, irAtras }) => {
    const history = useHistory()
    return (
        <header className='header-curve' style={Tamano && { paddingBottom: "5px" }}>
            {
                LogoPricipal &&
                <ReactSVG className='logo' src={Logo} />
            }

            {
                Titulo &&
                <div className="row valign-wrapper header-fixed" >
                    {
                        Back ?
                            <div className="col s2 center-align logo-icon" >
                                <p>
                                    <IonIcon class="icon-header icon-logo-white" icon={arrowBackOutline} color="light" onClick={irAtras}>
                                    </IonIcon>
                                </p>
                            </div>
                            :
                            <div className="col s2 center-align logo-icon" onClick={(e)=>history.push("/inicio")}>
                                <p>
                                    <span className="icon-header icon-logo-white"><span className="path1"></span><span className="path2"></span><span
                                        className="path3"></span><span className="path4"></span><span className="path5"></span><span
                                            className="path6"></span></span>
                                    <span className="mls"></span>
                                </p>
                            </div>
                    }
                    <div className="col s8 center-align">
                        <p className="white-text">{TituloText}<strong>{TituloStrong}</strong></p>
                    </div>
                    <div className="col s2 center-align menu-icon">
                        <p>

                        </p>
                    </div>
                </div>
            }
            {
                Pestañas &&
                <div className="header-text" >
                    <h3>{tituloPestana} <strong>{strongPestana}</strong></h3>
                </div>
            }
        </header >
    )
}

export default Header;