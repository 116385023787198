import React from 'react'
import Header from '../../../components/header/Header'
import IonPage from '../../../components/newPage'
import { useHistory } from 'react-router-dom'

import './pagar.css'
import { IonAccordion, IonAccordionGroup, IonContent, IonIcon, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton } from '@ionic/react'
import { createOutline, trashOutline, arrowDownCircle } from 'ionicons/icons';
function Pagar() {
    const history = useHistory()
    const irAtras = () => {
        history.push("/resumencompra")
    }
    const irDetallePago = () => {
        history.push("/DetallePago")
    }
    return (
        <IonPage>
            <Header Titulo={"true"} TituloText={`Pagar en jumbo`} Tamano={"true"} Back={"true"} irAtras={irAtras} />

            <IonContent scrollEvents={true} className="IonContent">
                <section class="inside tiposPago">
                    <div class="row">
                        <form class="col s12">
                            <h4>Código de descuentos</h4>
                            <div class="row">
                                <div class="input-field col s12">
                                    <input id="icon_prefix" type="text" class="validate" />
                                    <label for="icon_prefix">Agregar código</label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <IonSegment scrollable mode='ios' color="success" value="0">
                            <IonSegmentButton value="0">
                                <IonLabel>Tarjeta de Credito</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="1">
                                <IonLabel>PSE</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </div>
                    <div className="row">
                        <IonAccordionGroup >
                            <IonAccordion value="colors" toggleIcon={arrowDownCircle} className="IonAccordionLista">
                                <IonItem slot="header">
                                    <IonLabel>Lista Para compra navideña</IonLabel>
                                </IonItem>

                                <IonList slot="content">
                                    <IonItem>
                                        <IonLabel>primero</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Green</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Blue</IonLabel>
                                    </IonItem>
                                </IonList>
                            </IonAccordion>
                            <IonAccordion value="segudno" toggleIcon={arrowDownCircle} className="IonAccordionLista">
                                <IonItem slot="header">
                                    <IonLabel>Lista Para compra navideña</IonLabel>
                                </IonItem>

                                <IonList slot="content">
                                    <IonItem>
                                        <IonLabel>primero</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Green</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Blue</IonLabel>
                                    </IonItem>
                                </IonList>
                            </IonAccordion>
                            <IonAccordion value="tercero" toggleIcon={arrowDownCircle} className="IonAccordionLista">
                                <IonItem slot="header">
                                    <IonLabel>Lista Para compra navideña</IonLabel>
                                </IonItem>

                                <IonList slot="content">
                                    <IonItem>
                                        <IonLabel>primero</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Green</IonLabel>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Blue</IonLabel>
                                    </IonItem>
                                </IonList>
                            </IonAccordion>
                        </IonAccordionGroup>

                    </div>
                </section>
            </IonContent>

            <div className="finpagar">
                <div className="navbar-fixed pay">
                    <nav className="z-depth-4 border-up green-mercadu">
                        <div className="nav-wrapper center">
                            <div className="row">
                                <div className="col s12">
                                    <h4>Total a pagar: $350.000</h4>
                                    <a className="waves-effect waves-light btn-large border-all white-mercadu" onClick={irDetallePago}>Realizar Pago
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </IonPage>
    )
}

export default Pagar
