import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonList } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import Header from '../../../components/header/Header'
import IonPage from '../../../components/newPage'
import { arrowDownCircle } from 'ionicons/icons';
import './DetallePago.css'
import { useHistory, useLocation } from 'react-router';
import Moment from 'react-moment';

function DetallePago() {
    const { state } = useLocation()

    const [mercadu, setMercadu] = useState(null)

    const history = useHistory()

    useEffect(() => {
        console.log(state)
        if (!state) {
            return history.push("/mercadus")
        }
        setMercadu(state)
    }, [state])


    const getPrices = (Market) => {
        let priceMarket = Market?.listMarket?.reduce((previousValue, currentValue) => {
            return { totalMarket: parseInt(previousValue.totalMarket) + parseInt(currentValue.totalMarket) }
        }, { totalMarket: 0 })
        return priceMarket.totalMarket
    }

    return (
        <IonPage>
            <Header Titulo={"true"} TituloText={`Vas a mercar en`} Tamano={"true"} />
            <section class="inside DetallePago">
                <div class="row">
                    <div class="col s12">
                        <h4 className='titulo'>Detalles de Pago:</h4>
                        <div class="col s6">
                            <p>
                                <Moment format="DD/MM/YYYY">
                                    {mercadu?.createdAt}
                                </Moment><br />
                                Pago con TC XXXX
                            </p>
                        </div>
                        <div class="col s6 center-align">
                            <h4 class="green-mercadu precio">
                                $ {mercadu && getPrices(mercadu)}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <h4 className='titulo'>Detalles de la compra</h4>
                    <IonAccordionGroup >
                        {
                            mercadu?.listMarket?.length ?
                                mercadu?.listMarket?.map(merca => (
                                    <IonAccordion key={merca.id} value={merca.nameMarket} toggleIcon={arrowDownCircle} className="IonAccordionLista">
                                        <IonItem slot="header">
                                            <IonLabel>{merca.nameMarket}</IonLabel>
                                            <IonLabel className='totalMarket'>${merca.totalMarket}</IonLabel>
                                        </IonItem>
                                        <IonList slot="content">
                                            <IonItem>
                                                <table>
                                                    <thead>
                                                        <tr className='cabeceraTabla'>
                                                            <th>Productos </th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            merca.products.length ?
                                                                merca.products.map((product,i) => (
                                                                    <tr key={i}>
                                                                        <td className="producto">
                                                                            <img src={product.image} alt='img-product' />
                                                                            <div className="info-producto">
                                                                                <p className="nombre-producto">{product.name}</p>
                                                                                <p className="descripcion-producto">{product.name} x  {product.stock}</p>
                                                                                <p className="precio-producto">Valor Unidad: ${product.price}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className='precio'>${product.price}</td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td>
                                                                    <p>no hay productos</p>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </IonItem>
                                        </IonList>
                                    </IonAccordion>
                                ))

                                :
                                <p>No tienes lista de productos</p>
                        }

                    </IonAccordionGroup>

                </div>
            </section>
        </IonPage>
    )
}

export default DetallePago


