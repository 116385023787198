import React, { useEffect, useState } from 'react'
import IonPage from '../../../components/newPage'
import { useLocation, useHistory } from 'react-router-dom'
import Header from '../../../components/header/Header'
import { IonContent, useIonAlert } from '@ionic/react';
import './ProductoIndividual.css'

import { useDispatch } from 'react-redux';

function ProductoIndividual() {
    const history = useHistory();
    const { state } = useLocation()
    const dispatch = useDispatch()
    const [producto, setproducto] = useState()
    const [tienda, setTienda] = useState()
    const [quantityvalue, setQuantityvalue] = useState(0)
    const [showAlert, hideAlert] = useIonAlert();

    useEffect(() => {
        setproducto(state?.producto)
        setTienda(state?.tienda)
        return null
    }, [])

    const irAtras = () => {
        history.push("/agregarmercado", { ...tienda })
    }

    const quantity = (tipo) => {
        if (tipo) {
            setQuantityvalue(quantityvalue + 1)
        }
        else {
            if (quantityvalue > 1) {
                setQuantityvalue(quantityvalue - 1)
            }
        }
    }

    const AñadirProductoTienda = (producto) => {
        if (quantityvalue < 1) {
            alert("La cantidad no puede ser menor a 1")
        } else {
            let DataAñadir = {
                tienda: tienda?.nombreCanasta,
                espacio: tienda?.espacioCanasta,
                producto: { ...producto, cantidad: quantityvalue }
            }

            showAlert({
                cssClass: 'my-css',
                header: 'Alert',
                message: `Agregaste ${producto.nombre} a la canasta ${tienda?.nombreCanasta} !`,
                buttons: [{
                    text: `Volver A la canasta ${tienda?.nombreCanasta}`,
                    handler: () => history.push("/agregarmercado", { ...tienda })
                }],
            });

        }
    }

    return (
        <IonPage>
            <Header Titulo={"true"} TituloText={`Canasta ${tienda?.nombreCanasta}`} Tamano={"true"} Back={"true"} irAtras={irAtras} />
            <IonContent>
                <section className="inside">
                    <div className="row detalle-producto center-align">
                        <img className="responsive-img" src={producto?.imagen} />
                        <div className="info-producto">
                            <p className="nombre-producto">{producto?.nombre}</p>
                            <p className="descripcion-producto">{producto?.nombre} x {producto?.unidades} unidades</p>
                            <p className="precio-producto">Valor Unidad: <span>${producto?.valor}</span></p>
                            <div className="input-group plus-minus-input">
                                <div className="input-group-button">
                                    <button type="button" className="button hollow circle" onClick={() => quantity(false)}>
                                        -
                                    </button>
                                </div>
                                <input className="input-group-field" type="number" name="quantity" onChange={(e) => console.log(e.target.value)} value={quantityvalue} />
                                <div className="input-group-button">
                                    <button type="button" className="button hollow circle" onClick={() => quantity(true)}>
                                        +
                                    </button>
                                </div>
                            </div>
                            <a className="waves-effect waves-light btn-large border-all green-mercadu modal-trigger" onClick={() => AñadirProductoTienda(producto)}
                            >Agregar al Carrito</a>
                        </div>
                    </div>

                    <div className="divider"></div>

                    <div className="row productos-similares">
                        <div className="col s12 center-align">
                            <h3>
                                Productos Similares
                            </h3>
                        </div>
                        <div className="col s4 center-align">
                            <img className="responsive-img" src="../../../../assets/img/producto-mercadu-38.jpg" />
                            <div className="info-producto">
                                <p className="nombre-producto">Cápsula NESCAFÉ Chai</p>
                                <p className="descripcion-producto">Tea x 16 unidades</p>
                                <p className="precio-producto">Valor Unidad: <span>$19.950</span></p>
                            </div>
                        </div>
                        <div className="col s4 center-align">
                            <img className="responsive-img" src="../../../../assets/img/producto-mercadu-38.jpg" />
                            <div className="info-producto">
                                <p className="nombre-producto">Cápsula NESCAFÉ Chai</p>
                                <p className="descripcion-producto">Tea x 16 unidades</p>
                                <p className="precio-producto">Valor Unidad: <span>$19.950</span></p>
                            </div>
                        </div>
                        <div className="col s4 center-align">
                            <img className="responsive-img" src="../../../../assets/img/producto-mercadu-38.jpg" />
                            <div className="info-producto">
                                <p className="nombre-producto">Cápsula NESCAFÉ Chai</p>
                                <p className="descripcion-producto">Tea x 16 unidades</p>
                                <p className="precio-producto">Valor Unidad: <span>$19.950</span></p>
                            </div>
                        </div>
                    </div>
                </section >
            </IonContent >
        </IonPage >
    )
}

export default ProductoIndividual
