import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegiserService } from '../../../../services/auth.service';
import { AxiosError } from 'axios'

export interface AuthRegister {
    name: string,
    lastname: string,
    email: string,
    password: string,
    phone: string,
}

export const RegisterThunk = createAsyncThunk(
    'auth/register',
    async ({ email, password, phone, lastname, name }: AuthRegister, thunkAPI) => {
        try {
            const { data } = await RegiserService({ email, password, phone, lastname, name })
            return data
        } catch (error) {
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)