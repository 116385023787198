import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginService } from '../../../../services/auth.service';
import { set } from '../../../../utils/ionicStorage'
import { AxiosError } from 'axios'

export interface AuthLogin {
    email: string,
    password: string
}

export const LoginThunk = createAsyncThunk(
    'auth/login',
    async ({ email, password }: AuthLogin, thunkAPI) => {
        try {
            const { data } = await LoginService({ email, password })
            let token = data.token
            set('_token_user', token)
            return token;
        } catch (error) {
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)