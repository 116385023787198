import IonicPage from '../../../components/newPage'
import Header from '../../../components/header/Header.js'
import './Register.css'
import { Link, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useIonAlert } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { RegisterThunk } from '../../../redux/slices/auth/thunks/RegisterThunk'

const Register = () => {

  const dispatch = useDispatch();
  const history = useHistory()

  const [checked, setchecked] = useState(false)
  const [showAlert, hideAlert] = useIonAlert();
  const { register, handleSubmit } = useForm();


  const [errores, seterrores] = useState({
    name: '',
    lastname: '',
    email: '',
    password: '',
    phone: '',
    confirmPassword: '',
    terminos: ''
  })

  const onSubmit = (data) => {
    const { confirmPassword, password } = data

    if (confirmPassword === password) {
      return dispatch(RegisterThunk(data))
        .unwrap()
        .then((data) => {
          showAlert({
            cssClass: 'my-css',
            header: 'Success',
            message: `Usuario Registrado Correctamente`,
            buttons: [
              {
                text: 'Ir A Login',
                role: 'confirm',
                handler: () => {
                  history.push("login")
                },
              },
              {
                text: 'Cancel',
                role: 'cancel'
              },
            ],
          });
        })
        .catch(err => {
          showAlert({
            cssClass: 'my-css',
            header: 'Alert',
            message: err,
            buttons: ['Ok'],
          });
        })
    }

    showAlert({
      cssClass: 'my-css',
      header: 'Alert',
      message: `Las contraseñas no coinciden`,
      buttons: ['Ok'],
    });
  };

  const onError = (data) => {
    let tempErrores = {}
    tempErrores = { ...errores }
    Object.keys(tempErrores).forEach((error) => {
      if (data[error]) tempErrores[error] = data[error].message
      else tempErrores[error] = ""
    })
    seterrores(tempErrores)
  };

  return (
    <IonicPage header={false}>
      <Header LogoPricipal={"true"} />
      <section className="small-container">
        <h3 className="center-align subtitle-mercadu"><b>Regístrate y obtén descuentos y ofertas</b> personalizadas en tu
          supermercado favorito</h3>
        <div className="row">
          <form className="col s12 loginespacio" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="row">
              <div className="input-field col s12">
                <input {...register("name", { required: "El campo nombre es Requerido" })} type="text" className="validate" placeholder='Nombre' />
                {
                  errores.name !== "" && <span className='errorField'>{errores.name}</span>
                }
              </div>
              <div className="input-field col s12">
                <input {...register("lastname", { required: "El campo apellido es requerido" })} type="text" className="validate" placeholder='Apellido' />
                {
                  errores.lastname !== "" && <span className='errorField'>{errores.lastname}</span>
                }
              </div>
              <div className="input-field col s12">
                <input {...register("phone", { required: "El campo telefono es requerido" })} type="text" className="validate" placeholder='Telefono' />
                {
                  errores.phone !== "" && <span className='errorField'>{errores.phone}</span>
                }
              </div>
              <div className="input-field col s12">
                <input {...register("email", { required: "El campo correo es requerido" })} type="email" className="validate" placeholder='Correo Electronico' />
                {
                  errores.email !== "" && <span className='errorField'>{errores.email}</span>
                }
              </div>
              <div className="input-field col s12">
                <input {...register("password", { required: "El campo contraseña es requerido", minLength: { value: 6, message: "debe tener minimo 6 caracteres" } })} type="password" className="validate" placeholder='Contraseña' />
                {
                  errores.password !== "" && <span className='errorField'>{errores.password}</span>
                }
              </div>
              <div className="input-field col s12">
                <input id='password'  {...register("confirmPassword", { required: "El campo validar contraseña es requerido", minLength: { value: 6, message: "debe tener minimo 6 caracteres" } })} type="password" className="validate" placeholder='Confirmar Contraseña' />
                {
                  errores.confirmPassword !== "" && <span className='errorField'>{errores.confirmPassword}</span>
                }
              </div>
              <label className="checkbox_register">
                <input {...register("terminos", { required: "Debes aceptar terminos y condiciones" })} type="checkbox" checked={checked} onChange={(e) => setchecked(!checked)} />
                <span>Al crear una cuenta en Mercadú aceptas los
                  Términos, Condiciones uso y <a >Políticas de Privacidad</a>.</span>
                {
                  errores.terminos !== "" &&
                  <div>
                    <span className='errorField'>{errores.terminos}</span>
                  </div>
                }
              </label>

            </div>
            <div className="center-align">
              <button type="submit" className="waves-effect waves-light btn-large border-all green-mercadu" >Regístrarme</button>
            </div>
          </form>
        </div>
        <div className="section center-align">
          ¿Ya tienes una cuenta? <Link to={"/login"}>Inicia Sesión</Link>
        </div>
      </section>
    </IonicPage>
  );
};

export default Register;