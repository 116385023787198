import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import Login from '../pages/Auth/Login/Login.js'
import Register from '../pages/Auth/Register/Register.js'
import Tabs from '../components/Tabs/Tabs';
import InicioMercado from '../pages/Client/InicioMercado/InicioMercado.js'
import CrearMercado from '../pages/Client/CrearMercado/CrearMercado'
import AgregarMercado from '../pages/Client/AgregarMercado/AgregarMercado'
import ProductoIndividual from '../pages/Client/ProductoIndividual/ProductoIndividual'
import TerminarDeMercar from '../pages/Client/TerminarDeMercar/TerminarDeMercar'
import Pagar from '../pages/Client/pagar/pagar'
import DetallePago from '../pages/Client/DetallePago/DetallePago'
import DetalleCanasta from '../pages/Client/DetalleCanasta/DetalleCanasta'
import RegistrarProducto from '../pages/Client/RegistarProducto/RegistrarProducto';
import PrivateRoute from '../PrivateRoute'
import RouteAuth from '../RouteAuth'

const NewPage: React.FC = () => {

    return (
        <IonReactRouter  >
            <Tabs />
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/iniciomercar" component={InicioMercado} />
                <Route path="/crearmercado" component={CrearMercado} />
                <Route path="/agregarmercado" component={AgregarMercado} />
                <Route path="/productoindividual" component={ProductoIndividual} />
                <Route path="/resumencompra" component={TerminarDeMercar} />
                <Route path="/pagar" component={Pagar} />
                <Route path="/detallePago/:id" component={DetallePago} />
                <Route path="/detalleCanasta/:id" component={DetalleCanasta} />
                <Route path="/registrarProducto" component={RegistrarProducto} />
                <Redirect from="/" exact to="/login" />
            </Switch>
        </IonReactRouter>
    );
};

export default NewPage;