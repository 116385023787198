import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth/authSlice'
import productsReducer from './slices/products/productsSlice'
import listReducer from './slices/list/listSlice'
import MarketReducer from './slices/markets/marketsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productsReducer,
    list: listReducer,
    markets: MarketReducer
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch