import React, { useEffect, useRef, useState } from 'react'

import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { createOutline, trashOutline, arrowDownCircle, pin, addOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux'
import { getAllListById, createItemList, deleteItemList2, deleteListAction } from '../redux/slices/list/thunks/allListThunk'
import { decodeToken, useJwt } from "react-jwt";
import { get } from '../utils/ionicStorage'

import './Lista.css'
function Lista() {

    const dispath = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const { list } = useSelector(state => state.list)
    const modal = useRef(null);
    const [listId, setListId] = useState(null)
    const [itemList, setitemList] = useState("")
    const [showAlert] = useIonAlert();

    useEffect(() => {
        getToken()
            .then((token) => {
                const { userId } = decodeToken(token);
                dispath(getAllListById({ id: userId }))
                    .unwrap()
                    .then((data) => {
                        console.log("listas correctamente")
                    })
                    .catch((err) => console.log(err))
            })
    }, [])


    const getToken = async () => {
        const token = await get('_token_user')
        return token
    }

    const addToList = () => {
        dispath(createItemList({ listID: listId, nameitem: itemList }))
            .unwrap()
            .then((data) => {
                showAlert({
                    cssClass: 'my-css',
                    message: `item creado correctamente`,
                    buttons: [
                        { text: "Ok" }
                    ]
                });
                obtenerListas()
                modal.current?.dismiss()
                setIsOpen(false)
                setListId(null)
            })
            .catch((err) => console.log(err))
    }


    const obtenerListas = () => {
        getToken()
            .then((token) => {
                const { userId } = decodeToken(token);
                dispath(getAllListById({ id: userId }))
                    .unwrap()
                    .then((data) => {
                        console.log("listas correctamente")
                    })
                    .catch((err) => console.log(err))
            })
    }

    const deleteItemList = (id) => {
        dispath(deleteItemList2({ listitemID: id }))
            .unwrap()
            .then((data) => {
                showAlert({
                    cssClass: 'my-css',
                    message: `item eliminado correctamente`,
                    buttons: [
                        { text: "Ok" }
                    ]
                });
            })
            .catch((err) => console.log(err))
    }

    const deleteList = (id) => {
        showAlert({
            cssClass: 'my-css',
            message: `Deseas Eliminar la lista, se eliminaran cada uno de los items`,
            buttons: [
                {
                    text: "Ok",
                    handler: async () => {
                        dispath(deleteListAction({ idList: id }))
                        .unwrap()
                        .then(da=>{
                            console.log(da)
                            consultar()
                        })
                    }
                },
                { text: "Cancelar" }
            ]
        });
    }
    const consultar = () => {
        getToken()
            .then((token) => {
                const { userId } = decodeToken(token);
                dispath(getAllListById({ id: userId }))
                    .unwrap()
                    .then((data) => {
                        console.log(data)
                        console.log("listas correctamente")
                    })
                    .catch((err) => console.log(err))
            })
    }
    return (
        <>
            <IonAccordionGroup >
                {
                    list.length ?
                        list.map((lis, i) => (
                            <IonAccordion key={i} toggleIcon={arrowDownCircle} className="IonAccordionLista">
                                <IonItem slot="header">
                                    <IonLabel>{lis.nameList}</IonLabel>
                                </IonItem>
                                <IonList slot="content">
                                    {
                                        lis.itemsList.length ?
                                            lis.itemsList.map((item, i) => (
                                                <IonItem key={i}>
                                                    <IonLabel>{item.nameitem}</IonLabel>
                                                    <IonIcon icon={createOutline} color="primary" />
                                                    <IonIcon icon={trashOutline} color="danger" onClick={() => deleteItemList(item.id)} />
                                                </IonItem>
                                            ))
                                            :
                                            <IonItem>
                                                <IonLabel>No tienes productos</IonLabel>
                                            </IonItem>
                                    }
                                    <IonItem className='ion-justify-content-center'>
                                        <IonButton color='tertiary' onClick={() => {
                                            setIsOpen(true)
                                            setListId(lis.id)
                                        }}>
                                            <IonIcon icon={addOutline} color="success" />
                                            agregar a la lista
                                        </IonButton>
                                        <IonButton color='tertiary' onClick={() => { deleteList(lis.id) }}>
                                            <IonIcon icon={trashOutline} color="danger" />
                                            Eliminar lista
                                        </IonButton>
                                    </IonItem>
                                </IonList>
                            </IonAccordion>
                        ))
                        : <h1>No hay listas registradas</h1>
                }
            </IonAccordionGroup >
            <IonModal isOpen={isOpen} initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 0.75]} onWillDismiss={(ev) => setIsOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => {
                                modal.current?.dismiss()
                                setIsOpen(false)
                                setListId(null)
                            }}>Cancel</IonButton>
                        </IonButtons>
                        <IonTitle>Welcome</IonTitle>
                        <IonButtons slot="end">
                            <IonButton strong={true} onClick={addToList}>
                                Confirm
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonItem>
                        <IonLabel position="stacked">Agregar A la lista #{listId}</IonLabel>
                        <IonInput type="text" placeholder="Agregar A la lista" onIonChange={e => setitemList(e.target.value)} />
                    </IonItem>
                </IonContent>
            </IonModal>
        </>

    )
}

export default Lista
