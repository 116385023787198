import Api from '../utils/ConectionApi';
import authHeader from './AuthHeader'

const BaseUrl = 'list/'
const BaseUrlItemList = 'itemlist/'

export const GetListByUserId = async ({ id }) => {
    let headers = await authHeader()
    return Api({
        method: 'GET',
        url: `${BaseUrl}user/${id}`,
        headers
    })
}


export const PostListByUserId = async ({ nameList }) => {
    console.log(nameList)
    let headers = await authHeader()
    return Api({
        method: 'POST',
        url: `${BaseUrl}create`,
        headers,
        data: {
            nameList
        }
    })
}

export const PostCreateItemList = async ({ nameitem, listID }) => {
    let headers = await authHeader()
    return Api({
        method: 'POST',
        url: `${BaseUrlItemList}create/${listID}`,
        headers,
        data: {
            nameitem
        }
    })
}

export const DeleteItemList = async ({ listitemID }) => {
    let headers = await authHeader()
    return Api({
        method: 'DELETE',
        url: `${BaseUrlItemList}${listitemID}`,
        headers
    })
}


export const DeleteListApi = async ({ idList }) => {
    let headers = await authHeader()
    return Api({
        method: 'DELETE',
        url: `${BaseUrl}${idList}`,
        headers
    })
}