import {
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react';
import { Route } from 'react-router-dom';
import { timerOutline, listOutline, cartOutline, nutritionOutline, bagHandleOutline } from 'ionicons/icons';
import Inicio from '../../pages/Tabs/Inicio/Inicio';
import Listas from '../../pages/Tabs/Listas/Listas';
import Canasta from '../../pages/Tabs/Canastas/Canasta';
import ProductsTab from '../../pages/Tabs/Products/Products';
import MercaduTab from '../../pages/Tabs/Mercadus/Mercadus';
import './Tabs.css'
import PrivateRoute from '../../PrivateRoute';

const Tabs: React.FC = () => {
    return (
        <IonTabs className='IonTabs'>

            <IonRouterOutlet>
                <Route exact path="/inicio" component={Inicio} />
                <Route exact path="/listas" component={Listas} />
                <Route exact path="/canastas" component={Canasta} />
                <Route exact path="/mercadus"  component={MercaduTab}/>
                <Route exact path="/products" component={ProductsTab}/>
            </IonRouterOutlet>

            <IonTabBar slot="bottom" className="IonTabBar">
                <IonTabButton className='IonTabButton' tab="inicio" href="/inicio" >
                    <IonIcon icon={timerOutline} className="IonIcon" />
                    <IonLabel className="IonLabel">Promos</IonLabel>
                </IonTabButton>
                <IonTabButton className='IonTabButton' tab="listas" href="/listas">
                    <IonIcon icon={listOutline} />
                    <IonLabel>Listas</IonLabel>
                </IonTabButton>
                <IonTabButton className='IonTabButton' tab="canastas" href="/canastas">
                    <IonIcon icon={cartOutline} />
                    <IonLabel>Canastas</IonLabel>
                </IonTabButton>
                <IonTabButton className='IonTabButton' tab="mercadus" href="/mercadus">
                    <IonIcon icon={nutritionOutline} />
                    <IonLabel>Mercadus</IonLabel>
                </IonTabButton>
                {/* <IonTabButton className='IonTabButton' tab="tab5" href="/tab5">
                    <IonIcon icon={chatbubblesOutline} />
                    <IonLabel>Soporte</IonLabel>
                </IonTabButton> */}
                <IonTabButton className='IonTabButton' tab="products" href="/products">
                    <IonIcon icon={bagHandleOutline} />
                    <IonLabel>Products</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

export default Tabs;