import React, { useEffect, useRef, useState } from 'react'
import IonPage from '../../../components/newPage'
import Header from '../../../components/header/Header.js';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonRow, IonImg, IonText, IonFabButton, IonButton, IonModal, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonLabel, IonInput, useIonAlert } from '@ionic/react';

import { getAllProducts } from '../../../redux/slices/products/thunks/getProductsThunk'
import { useDispatch } from 'react-redux';

import { Camera } from '@awesome-cordova-plugins/camera';


import './products.css'

function Products() {
    const dispath = useDispatch()
    const [products, setproducts] = useState({})
    const [showAlert] = useIonAlert();
    const modal = useRef(null);

    const [createProduct, setcreateProduct] = useState({
        stock: 0,
        price: 0,
        barCode: 0,
        name: "",
        image: ''
    })

    useEffect(() => {
        getProducts(1)
    }, [])


    const getProducts = (id) => {
        dispath(getAllProducts({ page: id }))
            .unwrap()
            .then((data) => {
                setproducts(data)
            })
            .catch((err) => console.log(err))
    }

    const GetButtonsPagination = () => {
        const buttons = new Array(products.lastPage).fill(0)
        return buttons
    }

    const goTOpagination = (id) => {
        getProducts(id)
    }

    const openCamera = () => {
        Camera.getPicture({
            quality: 100,
            destinationType: Camera.DestinationType.DATA_URL,
            encodingType: Camera.EncodingType.JPEG,
            mediaType: Camera.MediaType.PICTURE,
            sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
            correctOrientation: true
        }).then((imgData) => {
            console.log("imagen en base 64", imgData)
        }).catch(err => {
            showAlert({
                cssClass: 'my-css',
                header: 'Alert',
                message: `${err}`,
                buttons: ['Cancel'],
            });
        })
    }

    return (
        <IonPage>
            <Header Titulo={"true"} TituloText={`Canasta `} Tamano={"true"} />
            <IonContent className='IonContentInicio ion-margin-bottom ion-padding-bottom' >
                <IonButton id="open-modal" expand="block" color="tertiary">Agregar Producto</IonButton>
                <IonGrid>
                    <IonRow>
                        {
                            products ?
                                products?.data?.map((product, i) => (
                                    <IonCol key={i} class="no-padding" size="6">
                                        <IonCard>
                                            <IonCardHeader>
                                                <IonImg src={product.image}></IonImg>
                                                <IonCardTitle class='ion-text-center'>{product.name}</IonCardTitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <ion-text color="secondary">
                                                    <h2>CODIGO:{product.barCode}</h2>
                                                </ion-text>
                                                <IonText color='tertiary'>
                                                    <h3>price: {product.price}</h3>
                                                </IonText>
                                                <p>stock: {product.stock}</p>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                ))
                                :
                                <h3>No hay productos registrados</h3>
                        }
                    </IonRow>
                </IonGrid>
                {
                    products &&
                    products.lastPage >= products.currentPage &&
                    <IonRow className='ion-justify-content-center ion-margin-bottom ion-padding-bottom'>
                        {
                            GetButtonsPagination().map((_, i) => (
                                <IonButton key={i} onClick={() => goTOpagination(i + 1)} color={products.currentPage == i + 1 ? 'tertiary' : 'primary'}>{i + 1}</IonButton>
                            ))
                        }
                    </IonRow>
                }


                <IonModal ref={modal} trigger="open-modal" >
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton strong={true} >
                                    Agregar
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <IonItem>
                            <IonLabel position="stacked">Nombre del producto</IonLabel>
                            <IonInput type="text" placeholder="Your name" />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Stock</IonLabel>
                            <IonInput type="text" placeholder="Your name" />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">precio</IonLabel>
                            <IonInput type="text" placeholder="Your name" />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Codigo de barras</IonLabel>
                            <IonInput type="text" placeholder="Your name" />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Codigo de barras</IonLabel>
                            <IonInput type="text" placeholder="Your name" />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Image</IonLabel>
                            <IonButton onClick={openCamera}>AddImage</IonButton>
                        </IonItem>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage >
    )
}

export default Products