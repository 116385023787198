
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
// Components
import IonicPage from '../../../components/newPage'
import Header from '../../../components/header/Header.js'
// Redux
import { useDispatch } from 'react-redux'
import { LoginThunk } from '../../../redux/slices/auth/thunks/loginThunk'
import { useIonAlert } from '@ionic/react';

import './Login.css'

const Login = () => {

  const history = useHistory()
  const dispath = useDispatch()
  const [showAlert, hideAlert] = useIonAlert();

  const [errores, seterrores] = useState({ password: '', email: '', general: '' })
  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => {
    seterrores({ password: '', email: '', general: '' })
    dispath(LoginThunk(data))
      .unwrap()
      .then(() => history.push("iniciomercar"))
      .catch(err => {
        showAlert({
          cssClass: 'my-css',
          header: 'Alert',
          message: `${err}`,
          buttons: ['Cancel'],
        });
        seterrores({ ...errores, general: err, password: "", email: "" })
      })
  };

  const onError = (data) => {
    let tempErrores = {}
    tempErrores = { ...errores }
    Object.keys(tempErrores).forEach((error) => {
      if (data[error]) tempErrores[error] = data[error].message
      else tempErrores[error] = ""
    })
    seterrores(tempErrores)
  }

  return (
    <IonicPage header={false}>
      <Header LogoPricipal={"true"} />
      <section className="small-container">
        <h3 className="center-align subtitle-mercadu"><b>Inicia Sesión</b> y disfruta de todos los beneficios y ofertas que
          tenemos para ti.</h3>
        <div className="row">
          <form className="col s12" onSubmit={
            handleSubmit(onSubmit, onError)
          }>
            <div className="row">
              <div className="input-field col s12">
                <input {...register("email", { required: "El campo correo es requerido" })} type="email" placeholder='Correo Electrónico' />
                {
                  errores.email !== "" &&
                  <span className='errorField'>{errores.email}</span>
                }
              </div>
              <div className="input-field col s12">
                <input  {...register("password", { required: "El campo password es requerido", minLength: { value: 6, message: "Debe tener minimo 6 caracteres" } })} type="password" placeholder='Contraseña' />
                {
                  errores.password !== "" &&
                  <span className='errorField'>{errores.password}</span>
                }
              </div>
            </div>
            <div className="center-align">
              {
                errores.general !== "" &&
                <div>
                  <span className='errorField'>{errores.general}</span>
                </div>
              }¨
              <button type="submit" className="waves-effect waves-light btn-large border-all green-mercadu" >Iniciar Sesión</button>
            </div>
          </form>
        </div>
        <div className="section center-align" >
          ¿No tienes cuenta? <Link to={"/register"}>Regístrate</Link>
        </div>
      </section>
    </IonicPage>
  );
};

export default Login;