import IonPage from '../../../components/newPage'
import Header from '../../../components/header/Header'
import { useLocation, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react';

import './InicioMercado.css'

const InicioMercado = () => {
    const history = useHistory()
    const { state } = useLocation();
    const [tienda, setTienda] = useState()

    useEffect(() => {
        setTienda(state?.tienda)
        if (!state) {
            history.push("/inicio")
        }
        return null
    }, [])


    const irACrearMercar = () => {
        history.push("/crearmercado", { tienda })
    }

    const irAtras = () => {
        history.push("/inicio")
    }

    return (
        <IonPage header={false}>
            <Header Titulo={"true"} TituloText={`Vas a mercar en ${tienda?.name}`} Tamano={"true"} Back={"true"} irAtras={irAtras} />


            <section className="inside">
                <div className="row header-mercar valign-wrapper">
                    <div className="col s3">
                        <img className="responsive-img border-all" src={tienda?.imagen} />
                    </div>
                    <div className="col s9">
                        <h4>¡Perfecto!
                            <small>Vamos a mercar en <b>{tienda?.name}</b></small>
                        </h4>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="row body-mercar">
                    <p>
                        Antes de iniciar vamos a crear la canasta donde vamos a ir agregando los productos que selecciones y así puedas
                        llevar un control de tu mercado.
                    </p>
                    <p>
                        Recuerda que puedes crear todas las canastas que necesitas, si requieres llevar cuentas por aparte.
                    </p>
                    <div className="crear-canasta">
                        <a className="border-all" onClick={() => irACrearMercar()}>
                            Crear Canasta <span className="icon-plus icon-green"><span className="path1"></span></span>
                        </a>
                    </div>
                </div>
            </section>

        </IonPage >
    );
};

export default InicioMercado;
