import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useHistory, useLocation } from 'react-router'
import Header from '../../../components/header/Header'
import IonPage from '../../../components/newPage'


function DetalleCanasta() {
    const { state } = useLocation()

    const [mercadu, setMercadu] = useState(null)

    const history = useHistory()

    useEffect(() => {
        setMercadu(state)
    }, [state])

    return (
        <IonPage>
            <Header Titulo={"true"} TituloText={`Vas a mercar en`} Tamano={"true"} />
            <section class="inside DetallePago">
                <div class="row">
                    <div class="col s12">
                        <h4 className='titulo'>Detalles de Pago:</h4>
                        <div class="col s6">
                            <p>
                                <Moment format="DD/MM/YYYY">
                                    {mercadu?.createdAt}
                                </Moment><br />
                                Pago con TC XXXX
                            </p>
                        </div>
                        <div class="col s6 center-align">
                            <h4 class="green-mercadu precio">
                                $ {mercadu?.totalMarket}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <h4 className='titulo'>Detalles de la compra</h4>
                    <table>
                        <thead>
                            <tr className='cabeceraTabla'>
                                <th>Productos </th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mercadu?.products?.length ?
                                    mercadu?.products?.map((product, i) => (
                                        <tr key={i}>
                                            <td className="producto">
                                                <img src={product.image} alt='img-product' />
                                                <div className="info-producto">
                                                    <p className="nombre-producto">{product.name}</p>
                                                    <p className="descripcion-producto">{product.name} x  {product.stock}</p>
                                                    <p className="precio-producto">Valor Unidad: ${product.price}</p>
                                                </div>
                                            </td>
                                            <td className='precio'>${product.price}</td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td>
                                            <p>no hay productos</p>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        </IonPage>
    )
}

export default DetalleCanasta