import IonPage from '../../../components/newPage'
import React, { useEffect, useState } from 'react'
import Header from '../../../components/header/Header'
import { decodeToken, useJwt } from "react-jwt";
import { useDispatch, useSelector } from 'react-redux'
import { get } from '../../../utils/ionicStorage'

import { getAllMarketsById } from '../../../redux/slices/markets/thunks/getMarketsThunk'

import Moment from 'react-moment';
import { Link } from 'react-router-dom';

function Mercadus() {
    const dispath = useDispatch()

    const { markets } = useSelector(state => state.markets)

    useEffect(() => {
        getToken()
            .then((token) => {
                const { userId } = decodeToken(token);
                dispath(getAllMarketsById({ user: userId }))
            })
    }, [])

    const getToken = async () => {
        const token = await get('_token_user')
        return token
    }

    const getPrices = (Market) => {
        let priceMarket = Market.listMarket.reduce((previousValue, currentValue) => {
            return { totalMarket: parseInt(previousValue.totalMarket) + parseInt(currentValue.totalMarket) }
        }, { totalMarket: 0 })
        return priceMarket.totalMarket
    }

    return (
        <IonPage >
            <Header Titulo={"true"} TituloText={"Mis Mercadus"} Tamano={"true"} />
            <section className="inside ion-justify-content-start">
                <div className="row lista-canasta">
                    <table>
                        <thead>
                            <tr>
                                <th>Supermercado</th>
                                <th>Fecha</th>
                                <th>Nº Canastas</th>
                                <th>Valor</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                markets.length > 0
                                    ?
                                    markets.map((market, i) => (
                                        <tr key={i}>
                                            <td>
                                                <b>
                                                    <Link to={{ pathname: `/detallePago/${market.id}`, state: market }}>{market.store}</Link>
                                                </b>
                                            </td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {market.createdAt}
                                                </Moment>
                                            </td>
                                            <td>{market.listMarket.length}</td>
                                            <td>${getPrices(market)}</td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td>
                                            <h1> No hay Mercadus</h1>
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        </IonPage>
    )
}

export default Mercadus