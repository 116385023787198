import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetProductService, UpdateInCardService, UpdateOutCardService, GetAllProducts, DeleteCardProducts,AddCardProducts } from '../../../../services/products.service'
import { AxiosError } from 'axios'


export const GetProductThunk = createAsyncThunk(
    'product/GetByBarCode',
    async ({ code }: { code: number }, thunkAPI) => {
        try {
            const { data } = await GetProductService({ code })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateInCard = createAsyncThunk(
    'product/updateInCard',
    async ({ id, inCard }: { id: number, inCard: number }, thunkAPI) => {
        try {
            const { data } = await UpdateInCardService({ id, inCard })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateOutCard = createAsyncThunk(
    'product/updateInCard',
    async ({ id, outCard }: { id: number, outCard: number }, thunkAPI) => {
        try {
            const { data } = await UpdateOutCardService({ id, outCard })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getAllProducts = createAsyncThunk(
    'product/getAll',
    async ({ page }: { page: number }, thunkAPI) => {
        try {
            const { data } = await GetAllProducts({ page })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const DeleteCountProducts = createAsyncThunk(
    'product/deleteCount',
    async ({ outCard, productID }: { outCard: number, productID: number }, thunkAPI) => {
        try {
            const { data } = await DeleteCardProducts({ outCard, productID })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)


export const ADDCountProducts = createAsyncThunk(
    'product/addCount',
    async ({ inCard, productID }: { inCard: number, productID: number }, thunkAPI) => {
        try {
            const { data } = await AddCardProducts({ inCard, productID })
            return data;
        } catch (error) {
            console.log(error)
            const err = error as AxiosError
            let message = err.response?.data.message
            return thunkAPI.rejectWithValue(message)
        }
    }
)