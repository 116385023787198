import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginThunk } from './thunks/loginThunk';

interface IinitialState {
    token: string,
    error: string
}

const initialState: IinitialState = {
    token: '',
    error: ''
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: {
        [LoginThunk.fulfilled.type]: (state: IinitialState, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        [LoginThunk.rejected.type]: (state: IinitialState, action: PayloadAction<string>) => {
            state.error = action.payload
        }
    },
})
const { reducer } = authSlice;

export default reducer;
