import { IonCol, IonContent, IonImg, IonRow } from '@ionic/react';
import { useHistory } from 'react-router-dom'
import Header from '../../../components/header/Header.js';
import IonPage from '../../../components/newPage'
import './Inicio.css';

// Imagenes Tiendas
import Ara from '../../../assets/img/Tiendas/ara.jpg'
import D1 from '../../../assets/img/Tiendas/d1.jpg'
import Easy from '../../../assets/img/Tiendas/easy.jpg'
import Exito from '../../../assets/img/Tiendas/exito.jpg'
import Jumbo from '../../../assets/img/Tiendas/jumbo.jpg'
import Metro from '../../../assets/img/Tiendas/metro.jpg'
import Justo from '../../../assets/img/Tiendas/justo.jpg'
// Imagenes Puclicidad
import Publicidad1 from '../../../assets/img/Publicidad/1.jpg'
import Publicidad2 from '../../../assets/img/Publicidad/2.jpg'
import { useState } from 'react';
const Tab1 = () => {
  const history = useHistory();

  const [Tiendas] = useState([
    {
      name: "Jumbo",
      imagen: Jumbo
    },
    {
      name: "Exito",
      imagen: Exito
    },
    {
      name: "Ara",
      imagen: Ara
    },
    {
      name: "Tiendas D1",
      imagen: D1
    }, {
      name: "Easy",
      imagen: Easy
    },
    {
      name: "Metro",
      imagen: Metro
    },
    {
      name: "Justo y Bueno",
      imagen: Justo
    }
  ])
  
  const irTienda = (tienda) => {
    history.push("/iniciomercar", { tienda })
  }

  return (
    <IonPage>
      <Header Titulo={"true"} TituloText={"¿Dónde vas a mercar hoy?"} Pestañas={"true"} tituloPestana={"Estos son los"} strongPestana={"supermercados cerca a tu ubicación"} />

      <IonContent className="IonContentInicio">
        <section className="containers-market-cards">
          <IonRow>
            {
              Tiendas.map((tienda, i) => (
                <IonCol key={i} size='4' className="IonCol" onClick={() => irTienda(tienda)}>
                  <IonImg className="IonImg" src={tienda.imagen}></IonImg>
                </IonCol>
              ))
            }
            <section className="ad-home">
              <IonImg className="imgPublicidad" src={Publicidad1}></IonImg>
              <IonImg className="imgPublicidad" src={Publicidad2}></IonImg>
            </section>
          </IonRow>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
