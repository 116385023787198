import { AuthLogin } from '../redux/slices/auth/thunks/loginThunk';
import { AuthRegister } from '../redux/slices/auth/thunks/RegisterThunk';
import Api from '../utils/ConectionApi';

const BaseUrl = 'auth/'

export const LoginService = ({ email, password }: AuthLogin) => {
    return Api({
        method: 'POST',
        url: `${BaseUrl}login`,
        data: { email, password }
    })
}

export const RegiserService = ({ email, lastname, name, password, phone }: AuthRegister) => {
    return Api({
        method: 'POST',
        url: `${BaseUrl}sign-up`,
        data: { email, lastname, name, password, phone }
    })
}
