import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

const NewPage: React.FC<{ header: boolean }> = ({ children, header }) => {
    return (
        <IonPage>
            {
                header &&
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Login</IonTitle>
                    </IonToolbar>
                </IonHeader>
            }
            <IonContent fullscreen>
                {children}
            </IonContent>
        </IonPage>
    );
};

export default NewPage;