import IonPage from '../../../components/newPage'
import Header from '../../../components/header/Header'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';


import { GetListMarket } from '../../../redux/slices/markets/thunks/getMarketsThunk'

import { get } from '../../../utils/ionicStorage'
import { decodeToken } from 'react-jwt';
import { Link } from 'react-router-dom';

// import './Tab3.css';
function Canasta() {
  const dispath = useDispatch()
  const [listMarkets, setListMarkets] = useState([])

  useEffect(() => {
    dispath(GetListMarket())
      .unwrap()
      .then((data) => {
        getCanastasById(data.data)
      })
      .catch((err) => console.log(err))

  }, [])


  const getCanastasById = async (canastas) => {
    const token = await get('_token_user')
    const { userId } = decodeToken(token);
    let canastasFilter = canastas.filter((canasta) => canasta.market.user.id === userId)
    setListMarkets(canastasFilter)
  }

  return (
    <IonPage >
      <Header Titulo={"true"} TituloText={"Mis Mercadus"} Tamano={"true"} />
      <section className="inside ion-justify-content-start">
        <div className="row lista-canasta">
          <table>
            <thead>
              <tr>
                <th>Nombre Canasta</th>
                <th>NºProductos</th>
                <th>Valor</th>
              </tr>
            </thead>

            <tbody>
              {
                listMarkets.length > 0
                  ?
                  listMarkets.map((market, i) => (
                    <tr key={i}>
                      <td>
                        <b>
                          <Link to={{ pathname: `/detalleCanasta/${market.id}`, state: market }}>{market.nameMarket}</Link>
                        </b>
                      </td>
                      <td>
                        {
                          market.products.length
                        }
                      </td>
                      <td>{market.totalMarket}</td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td>
                      <h1> No hay Canastas</h1>
                    </td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </section>
    </IonPage>
  );
};

export default Canasta;
