import { useIonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Header from '../../../components/header/Header'
import IonPage from "../../../components/newPage"
import './CrearMercado.css'

function CrearMercado() {
    const history = useHistory()
    const { state } = useLocation();

    const [tienda, setTienda] = useState()
    const [nombreCanasta, setnombreCanasta] = useState("")

    const [showAlert] = useIonAlert();

    useEffect(() => {
        setTienda(state.tienda)
        return null
    }, [])

    const irAtras = () => {
        history.push("/iniciomercar", { tienda })
    }

    const guardarCanasta = (e) => {
        e.preventDefault()
        if (nombreCanasta === "") {
            showAlert({
                cssClass: 'my-css',
                header: 'Alert',
                message: `El nombre de la canasta no puede ser vacio`,
                buttons: ['Ok'],
            });
        } else {
            history.push("/agregarmercado", { ...tienda, nombreCanasta: nombreCanasta })
        }
    }

    return (
        <IonPage>
            <Header Titulo={"true"} TituloText={`Canasta ${tienda?.nombreCanasta}`} Tamano={"true"} Back={"true"} irAtras={irAtras} />

            <section className="inside">
                <div className="row header-mercar valign-wrapper">
                    <div className="col s3">
                        <img className="responsive-img border-all" src={tienda?.imagen} alt="img-shop" />
                    </div>
                    <div className="col s9">
                        <h4>¡Perfecto!
                            <small>Vamos a mercar en <b>{tienda?.name}</b></small>
                        </h4>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="row body-mercar">
                    <p>
                        Genial, ahora vamos a darle un nombre a la canasta para que puedas identificarla fácilmente.
                    </p>
                    <div className="nombrar-canasta">
                        <div className="row">
                            <form className="col s12">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <input value={nombreCanasta} onChange={(e) => setnombreCanasta(e.target.value)} type="text" className="validate" placeholder='Nombre de la canasta' />
                                    </div>
                                </div>
                                <div className="center-align">
                                    <button onClick={guardarCanasta} className="waves-effect waves-light btn-large border-all green-mercadu">Empezar a mercar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </IonPage>
    )
}

export default CrearMercado
