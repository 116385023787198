import Header from '../../../components/header/Header';
import IonPage from '../../../components/newPage'
import Lista from '../../../components/Lista.js'
import './Listas.css';
import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { useRef, useState } from 'react';

import './Listas.css'
import { useDispatch } from 'react-redux';

import { createList, getAllListById } from '../../../redux/slices/list/thunks/allListThunk'
import { get } from '../../../utils/ionicStorage'
import { decodeToken } from 'react-jwt';

const Tab2 = () => {
  const modal = useRef(null);
  const input = useRef(null);

  const dispath = useDispatch()
  const [showAlert] = useIonAlert();
  const [isOpen, setIsOpen] = useState(false);

  const [inputCrear, setinputCrear] = useState('')

  const crearLista = () => {
    console.log(inputCrear)
    dispath(createList({ nameList: inputCrear }))
      .unwrap()
      .then((data) => {
        showAlert({
          cssClass: 'my-css',
          message: `Input creado correctamente`,
          buttons: [
            { text: "Ok" }
          ]
        });
        obtenerListas()
        modal.current?.dismiss()
        setIsOpen(false)

      })
      .catch((err) => console.log(err))
  }

  const obtenerListas = () => {
    getToken()
      .then((token) => {
        const { userId } = decodeToken(token);
        dispath(getAllListById({ id: userId }))
          .unwrap()
          .then((data) => {
            console.log("listas correctamente")
          })
          .catch((err) => console.log(err))
      })
  }

  const getToken = async () => {
    const token = await get('_token_user')
    return token
  }

  return (
    <IonPage >
      <Header Titulo={"true"} TituloText={"Mis listas Mercadu"} Tamano={"true"} />
      <section className="inside tiposPago">
        <div className="row">
          <div className="col s12">
            <h4>Estas son tus Listas</h4>
          </div>
        </div>
        <div className="row">
          <Lista />
          <div class="floating-bottom">
            <button class="add-list modal-trigger" onClick={() => setIsOpen(true)}>
              Añadir Lista
              <span class="icon-plus"><span class="path1"></span></span>
            </button>
          </div>
        </div>
      </section>

      <IonModal isOpen={isOpen} initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 0.75]} onWillDismiss={(ev) => setIsOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => {
                modal.current?.dismiss()
                setIsOpen(false)
              }} >Cancel</IonButton>
            </IonButtons>
            <IonTitle>Welcome</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={crearLista}>
                Confirm
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel position="stacked">Crear Lista</IonLabel>
            <IonInput ref={input} type="text" onIonChange={e => setinputCrear(e.target.value)} placeholder="Nombre Lista" />
          </IonItem>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Tab2;
