import React, { useEffect, useState } from 'react'
import Header from '../../../components/header/Header'
import IonicPage from '../../../components/newPage'
import { useHistory, useLocation } from "react-router-dom"
import { IonButton, IonContent, IonLabel, IonSegment, IonSegmentButton, useIonAlert } from '@ionic/react'

import './TerminarDeMercar.css'
import { useDispatch } from 'react-redux'
import { CreateMarket, CreateListItemMarket, getAllMarketsById } from '../../../redux/slices/markets/thunks/getMarketsThunk'
import { get } from '../../../utils/ionicStorage'
import { decodeToken } from 'react-jwt'
import { ADDCountProducts } from '../../../redux/slices/products/thunks/getProductsThunk'




function TerminarDeMercar() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { state } = useLocation();
    const [showAlert] = useIonAlert();

    const [dataMercadu, setDataMercadu] = useState({})
    const [activecanasta, setactivecanasta] = useState(0)
    const [canastas, setCanastas] = useState([])

    const irAtras = () => {
        history.push("/agregarmercado", { canastas: canastas })
    }

    useEffect(() => {
        setCanastas(state)
    }, [state])


    const irPagar = () => {
        showAlert({
            cssClass: 'my-css',
            header: 'Alert',
            message: `Boton desabilitado`,
            buttons: [
                { text: 'Ok' }
            ],
        });
    }

    const GuardarCanasta = async () => {
        try {


            let canastaProductAmount = canastas.map(ca => {
                return ca.products.map(pro => {
                    return { id: pro.id, amount: pro.amount }
                })
            })

            let caProdutsId = canastas.map((ca) => {
                ca.totalMarket = ca.products.reduce((previousValue, currentValue) => {
                    let suma = (previousValue !== 0 ? previousValue.price * previousValue.amount : 0) + (currentValue.price * currentValue.amount)
                    return suma
                }, 0)
                ca.products = ca.products.map(product => {
                    return product.id
                })
                return ca
            })

            canastaProductAmount.forEach(async (caPro) => {
                caPro.forEach(async (prod) => {
                    const data = await dispatch(ADDCountProducts({ inCard: prod.amount, productID: prod.id })).unwrap()
                    console.log(data)
                })
            })

            const token = await get('_token_user')
            const { userId } = decodeToken(token);

            const { id } = await dispatch(CreateMarket({ market: { store: caProdutsId[0].store } })).unwrap()

            caProdutsId.forEach(async (ca) => {
                console.log({ idMarket: id, DataListMarket: ca })
                const data = await dispatch(CreateListItemMarket({ idMarket: id, DataListMarket: ca })).unwrap()
                console.log(data)
                await dispatch(getAllMarketsById({ user: userId })).unwrap()
            })


            showAlert({
                cssClass: 'my-css',
                header: 'Alert',
                message: `Ver tus Mercadus`,
                buttons: [
                    {
                        text: 'Ok', handler: () => {
                            history.push('/mercadus')
                        }
                    },
                ],
            });
        } catch (error) {
            console.log(error)
        }
    }

    const totalIndividualMercadu = () => {
        return canastas[activecanasta]?.products.reduce((previousValue, currentValue) => {
            let suma = (previousValue !== 0 ? previousValue.price * previousValue.amount : 0) + (currentValue.price * currentValue.amount)
            return suma
        }, 0)
    }

    const totalMercadu = () => {
        let sumaCanastas = canastas.reduce((previousValue, currentValue) => {
            if (previousValue === 0) {
                return currentValue.products.reduce((previousValue, currentValue) => {
                    let suma = (previousValue !== 0 ? previousValue.price * previousValue.amount : 0) + (currentValue.price * currentValue.amount)
                    return suma
                }, 0)
            }
            else {
                return previousValue + currentValue.products.reduce((previousValue, currentValue) => {
                    let suma = (previousValue !== 0 ? previousValue.price * previousValue.amount : 0) + (currentValue.price * currentValue.amount)
                    return suma
                }, 0)
            }
        }, 0)

        return sumaCanastas
    }

    return (
        <IonicPage>
            <Header Titulo={"true"} TituloText={`Compra de #canastas ${canastas.length}`} Tamano={"true"} Back={"true"} irAtras={irAtras} />

            <IonContent scrollEvents={true} className="ContetntTerminarMercar" >
                <section className="inside resumen">
                    <div className="row">
                        {
                            canastas.length > 1 &&
                            <div className="col s12">
                                <h3><small>Total de tu mercadú:</small> ${totalMercadu()}</h3>

                                {
                                    canastas.map((ca, i) => (
                                        <IonButton key={i} onClick={() => setactivecanasta(i)}>{ca.nameMarket}</IonButton>
                                    ))
                                }
                            </div>
                        }
                        <div id="canasta1" className="col s12">
                            <h3><small>Total de tu canasta:</small> ${totalIndividualMercadu()}</h3>
                            <h3>Detalle de tu canasta</h3>
                            <div className="divider"></div>
                        </div>
                        <section className="inside" >
                            <div className="divider"></div>
                            <div className="row lista-canasta">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Productos</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            canastas.length ?
                                                canastas[activecanasta]?.products.map((product, i) => (
                                                    <tr key={i}>
                                                        <td className="producto" style={{ padding: "0" }}>
                                                            <img src={product.image} alt='img-product' />
                                                            <div className="info-producto">
                                                                <p className="nombre-producto">{product.name}</p>
                                                                <p className="descripcion-producto">{product.name} x  {product.stock}</p>
                                                                <p className="precio-producto">Valor Unidad: ${product.price}</p>
                                                                <p className="cantidad-producto">Cantidad: {product.amount}</p>
                                                            </div>
                                                        </td>
                                                        <td className='precio'>${product.price * product.amount}</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td>
                                                        <p>No hay canastas</p>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </section>
                    </div>
                </section>
            </IonContent >

            <div className="fin">
                <nav className="z-depth-4 border-up violet-bg">
                    <div className="nav-wrapper center">
                        <div className="row">
                            <div className="col s6">
                                <a className="waves-effect waves-light btn-large1 border-all green-mercadu btn-pagar" onClick={GuardarCanasta}>Guardar Canasta</a>
                            </div>
                            <div className="col s6">
                                <a className="waves-effect waves-light btn-large1 border-all white-mercadu btn-pagar" onClick={irPagar}>Pagar Canasta</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </IonicPage>
    )
}

export default TerminarDeMercar
