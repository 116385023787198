import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllMarketsById } from './thunks/getMarketsThunk';

interface IinitialState {
    markets: [],
    error: string
}

const initialState: IinitialState = {
    markets: [],
    error: ''
}

const authSlice = createSlice({
    name: "markets",
    initialState,
    reducers: {},
    extraReducers: {
        [getAllMarketsById.fulfilled.type]: (state: IinitialState, action: PayloadAction<[]>) => {
            state.markets = action.payload
        },
        [getAllMarketsById.rejected.type]: (state: IinitialState, action: PayloadAction<string>) => {
            state.error = action.payload
        }
    },
})
const { reducer } = authSlice;

export default reducer;
